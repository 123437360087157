@import "../../styles/variables.module";
.topNavbar {
  & + .container {
    .breadcrumb {
      margin-bottom: 0 !important;
    }
  }
  header {
    background: -webkit-linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0) 92.57%
    );
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0) 92.57%
    );
    padding: 75px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include extra_large_device {
      padding: 50px 0;
    }
    @include large_device {
      padding: 30px 0;
      &.cheapestDetail {
        margin-top: 60px;
      }
    }
    .headerRight {
      position: relative;
      z-index: 0;
      @include large_device {
        width: 415px;
        margin: 0 auto;
      }
      @include mobile_device {
        width: 100%;
        z-index: inherit;
      }
    }
    .cheapestDetail {
      position: relative;
      z-index: 1;
      @include large_device {
        text-align: center;
        margin-bottom: 20px;
      }
      .title {
        color: $theme-custom-color;
        font-weight: 700;
        font-size: 46px;
        line-height: 56px;
        margin-bottom: 24px;
        @media screen and (min-width: 1200px) and (max-width: 1399px) {
          font-size: 36px;
          line-height: 42px;
          margin-bottom: 10px;
          width: 100%;
        }
        @include extra_large_device {
          font-size: 32px;
          line-height: 40px;
          width: 100%;
        }
        @include large_device {
          font-size: 30px;
          line-height: 38px;
          width: 100%;
        }
        span {
          position: relative;
          z-index: 0;
          display: inline-block;
          &:before {
            // content: "";
            position: absolute;
            width: 100%;
            background-color: #4267d7;
            height: 10px;
            bottom: -7px;
            z-index: -1;
            @include extra_large_device {
              bottom: -3px;
            }
          }
        }
      }
      .desc {
        color: $desc-font;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 32px;
        @include extra_large_device {
          font-size: 16px;
          line-height: 24px;
        }
        @include mobile_device {
          font-size: 14px;
          line-height: 28px;
          margin-bottom: 30px;
        }
      }
      .playVideo {
        cursor: pointer;
        margin-left: 40px;
        @include mobile_device {
          margin: 10px auto 0 !important;
          justify-content: center;
        }
        span {
          color: $theme-custom-color;
          font-weight: 500;
        }
        .icon {
          height: 54px;
          -webkit-transition: 0.4s ease-in-out;
          transition: 0.4s ease-in-out;
          @include mobile_device {
            height: 44px;
            width: 44px;
          }
          &:hover {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
          }
          svg {
            @include mobile_device {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
      .btn {
        width: 240px;
        @include extra_small {
          width: 100%;
        }
      }
      .tabList {
        -webkit-column-count: inherit !important;
        column-count: inherit !important;
        @include extra_large_device {
          margin-top: 20px;
          margin-bottom: 20px;
        }
        tbody {
          display: block;
          tr {
            display: block;
            -webkit-column-count: 2;
            column-count: 2;
            td {
              color: $theme-custom-color;
              font-size: 18px;
              font-weight: 500;
              line-height: 28px;
              margin-bottom: 15px;
              text-align: left;
              position: relative;
              display: block;
              @include extra_large_device {
                font-size: 16px;
                margin-bottom: 10px;
              }
              @include large_device {
                font-size: 16px;
                margin-bottom: 10px;
              }
              @include extra_small {
                font-size: 13px;
                margin-bottom: 10px;
                line-height: 20px;
              }
              p {
                margin: 0;
              }
            }
          }
        }
      }
      .list {
        margin-top: 40px;
        margin-bottom: 40px;
        -webkit-column-count: 2;
        column-count: 2;
        @media screen and (min-width: 1200px) and (max-width: 1399px) {
          margin-top: 30px;
        }
        @media screen and (min-width: 768px) and (max-width: 991px) {
          width: 60%;
          margin: 30px auto;
          text-align: left;
        }
        @media screen and (min-width: 576px) and (max-width: 767px) {
          width: 85%;
          margin: 30px auto;
          text-align: left;
        }
        @include extra_large_device {
          margin-top: 20px;
          margin-bottom: 20px;
        }
        @include mobile_device {
          width: 90%;
          margin: 30px auto;
        }
        @include extra_small {
          margin-bottom: 15px;
        }
        li {
          color: $theme-custom-color;
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
          margin-bottom: 15px;
          padding-left: 35px;
          text-align: left;
          position: relative;
          @include extra_large_device {
            font-size: 16px;
            margin-bottom: 10px;
          }
          @include mobile_device {
            font-size: 14px;
          }
          @include extra_small {
            font-size: 13px;
            margin-bottom: 10px;
            line-height: 22px;
            padding-left: 25px;
          }
        }
      }
    }
    .bottomStar {
      position: relative;
      div {
        color: $theme-custom-color !important;
      }
      @include large_device {
        width: 70%;
        margin: 0 auto;
      }
      @media screen and (min-width: 576px) and (max-width: 767px) {
        width: 90%;
      }
      @include mobile_device {
        margin-bottom: 0;
        width: 100%;
        .row {
          margin: 0;
        }
      }
    }
    .orderForm {
      position: relative;
      background: $white-color;
      border-radius: 6px;
      width: 380px;
      margin-left: auto;
      margin-right: 30px;
      padding: 30px 0 20px;
      -webkit-box-shadow: 0px 5px 13px rgba(64, 85, 196, 0.14);
      box-shadow: 0px 5px 13px rgba(64, 85, 196, 0.14);
      min-height: 433px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include extra_large_device {
        width: 320px;
      }
      @include mobile_device {
        margin: 0 auto;
      }
      @include extra_small {
        width: 100%;
      }
      &:before,
      &::after {
        content: "";
        position: absolute;
        width: 100px;
        height: 100px;
        background-repeat: no-repeat;
        z-index: -3;
        @include mobile_device {
          content: none;
        }
      }
      &:before {
        background-image: url("../" + $bg-base-path + "/header-dots.svg");
        right: -29px;
        top: -21px;
        @include extra_large_device {
          right: -20px;
        }
      }
      &::after {
        background-image: url("../" + $bg-base-path + "/header-dots.svg");
        left: -22px;
        bottom: -30px;
      }
      .loader {
        text-align: center;
      }
      .title {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 22px;
        text-align: center;
        margin-bottom: 24px;
        color: $theme-custom-color;
        line-height: 27px;
        @media screen and (min-width: 1200px) and (max-width: 1399px) {
          font-size: 20px;
        }
        @include extra_small {
          font-size: 18px;
        }
      }
      .calculateDropdown {
        padding: 0 32px;
        margin-bottom: 20px;
        @media screen and (min-width: 1200px) and (max-width: 1399px) {
          margin-bottom: 10px;
        }
        @include extra_large_device {
          padding: 0px 20px;
        }
        @include mobile_device {
          margin-bottom: 10px;
        }
        .formGroup {
          margin-bottom: 16px;
          select {
            background-color: #f2f6f8;
            border: none;
            line-height: 26px;
          }
          &.wordPageMenu {
            .numberOfPages {
              [class*="selectInput__control"] {
                border-radius: 0;
              }
              select {
                width: calc(100% - 80px);
                border-radius: 0;
              }
              .counter {
                flex: 1;
                font-size: 20px;
                line-height: 38px;
                cursor: pointer;
                text-align: center;
                background: #f2f6f8;
                &.decrement {
                  border-radius: $border-radius 0 0 $border-radius;
                }
                &.increment {
                  border-radius: 0 $border-radius $border-radius 0;
                }
              }
            }
          }
          p {
            &.errorText {
              color: #e50f36;
              font-size: 13px;
              margin-top: 4px;
              font-weight: 400;
              text-align: left;
              line-height: normal;
            }
          }
        }
        .calculatePrice {
          .off {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #04be5b;
            background: rgba(4, 190, 91, 0.1);
            border-radius: 6px;
            padding: 4px;
            width: 90px;
          }
          .standPrice {
            color: $theme-custom-color;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            width: 100%;
            text-align: right;
            span {
              font-size: 16px;
              color: #5f6368;
              font-weight: 400;
              vertical-align: bottom;
              &.orderPrice {
                text-decoration: line-through;
              }
            }
          }
        }
        .list {
          border-radius: $border-radius;
          overflow: hidden;
          .listRdo {
            position: relative;
            flex: 1;
            &:first-child {
              border-radius: $border-radius 0 0 $border-radius;
              label {
                border-radius: $border-radius 0 0 $border-radius !important;
              }
            }
            &:nth-child(2) {
              border-radius: 0;
              label {
                border-radius: 0 !important;
              }
            }
            &:last-child {
              border-radius: 0 $border-radius $border-radius 0;
              label {
                border-radius: 0 $border-radius $border-radius 0 !important;
              }
            }
            input[type="radio"] {
              &:checked {
                & + .btn {
                  background-color: $white-color;
                  border-color: $secondary-color;
                }
              }
            }
          }
          .btn {
            padding: 7px 0;
            background: #f2f6f8;
            color: $theme-custom-color;
            border-color: transparent;
            margin-left: 0;
            width: 100%;
            font-weight: 400;
            &::before {
              content: none;
            }
          }
        }

        .dropdown {
          .dropdownMenu {
            width: 100%;
            border: 1px solid #f2f6f8;
            max-height: 230px;
            overflow: auto;
            .dropdown-item {
              font-size: 14px;
              span {
                padding: 8px 15px;
                font-size: 14px;
              }
            }
            & > span {
              font-weight: 500;
            }
          }
          span {
            width: 100%;
            display: flex;
            padding: 12px 10px 8px;
            font-size: 12px;
            color: $black-color;
            font-weight: 400;
          }
          .selectDropdown {
            width: 100%;
            margin-top: 15px;
            border: none;
            padding: 8px 32px 8px 15px;
            border-radius: $border-radius;
            text-align: left;
            color: $theme-color;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            position: relative;
            background-color: #f2f6f8;
            @include extra_large_device {
              margin-top: 8px;
            }
            @include mobile_device {
              padding: 6px 30px 6px 10px;
            }
            &:before {
              content: none;
            }
            &:after {
              content: "";
              background-image: url("../" + $bg-base-path + "/dropdown-toggle.svg");
              width: 12px;
              height: 6px;
              position: absolute;
              background-repeat: no-repeat;
              top: 50%;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              background-size: contain;
              right: 15px;
              -webkit-transition: all 0.2s ease-in-out;
              transition: all 0.2s ease-in-out;
            }
          }
          &.show {
            .selectDropdown {
              &:after {
                -webkit-transform: translateY(-50%) rotate(180deg);
                transform: translateY(-50%) rotate(180deg);
              }
            }
          }
        }
      }
      .standardPrice {
        font-size: 18px;
        text-align: center;
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 20px;
        margin-top: 0;
        @media screen and (min-width: 1200px) and (max-width: 1399px) {
          margin-bottom: 10px;
        }
        @include mobile_device {
          margin-top: 0;
          font-size: 14px;
        }
        span {
          font-size: 22px;
          display: inline-block;
          vertical-align: middle;
          padding-left: 10px;
          @include mobile_device {
            font-size: 16px;
          }
        }
      }
      .standardPrice.strikeText {
        span {
          text-decoration: line-through;
        }
      }
      .pricebox {
        background: $secondary-color;
        padding: 17px 30px;
        margin-bottom: 20px;
        position: relative;
        @media screen and (max-width: 1399px) {
          .pricebox {
            padding: 16px 20px;
          }
        }
        .discountSpan {
          text-align: center;
          font-weight: 600;
          font-size: 18px;
          width: 100%;
          display: inline-block;
        }
        .details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left {
            flex: 2;
            .text1 {
              font-size: 14px;
              line-height: 21px;
              margin-bottom: 0;
              color: $theme-custom-color;
            }
            .text2 {
              font-size: 12px;
              margin-bottom: 5px;
              line-height: 21px;
              color: $theme-custom-color;
            }
          }
          .right {
            flex: 1;
            text-align: center;
            .price {
              font-size: 30px;
              font-weight: 700;
              @include large_device {
                font-size: 22px;
              }
            }
          }
        }
        .inputWrpper {
          display: flex;
          .formControl {
            border-radius: 5px !important;
            font-size: 12px;
            color: $black-color;
            height: 40px;
            &:focus {
              outline: none;
              border-color: transparent;
              -webkit-box-shadow: none;
              box-shadow: none;
            }
          }
          .unlockBtn {
            margin-left: 25px;
            background: $black-color;
            color: $white-color;
            font-size: 16px;
            font-weight: 500;
            width: 200px;
            border-radius: $border-radius;
            padding: 0;
            @include large_device {
              margin-left: 10px;
            }
            &:before {
              border-color: $black-color;
              border-radius: $border-radius;
            }
          }
        }
      }
      .bottomPart {
        padding: 0 30px;
      }
      .orderBtn {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 18px;
        width: 100%;
        border-radius: 6px;
        background-color: $secondary-color;
        color: $theme-custom-color;
        z-index: 0;
        line-height: 24px;
        @include mobile_device {
          font-size: 16px;
        }
        &:after {
          content: "";
          position: absolute;
          background-image: url("../" + $bg-base-path + "/ordernowArrow.svg");
          background-repeat: no-repeat;
          background-size: 15px;
          width: 15px;
          height: 15px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          -webkit-animation: move 0.8s linear infinite alternate;
          animation: move 0.8s linear infinite alternate;
          @keyframes move {
            0% {
              right: 24px;
            }
            50% {
              right: 30px;
            }
            100% {
              right: 24px;
            }
          }
        }
      }
    }
  }
  .headerBottom {
    background: $theme-color;
    .list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        flex: 1;
        text-align: center;
        overflow: hidden;
        .listLink {
          color: $white-color;
          display: block;
          padding: 15px 10px;
          position: relative;
          line-height: 20px;
          font-size: 15px;
          z-index: 0;
          span {
            position: relative;
          }
          &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #4267d7;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
            -webkit-transition: all 0.5s ease-out;
            transition: all 0.5s ease-out;
          }
          &:hover {
            &::before {
              -webkit-transform: scaleX(1);
              transform: scaleX(1);
              -webkit-transition-timing-function: cubic-bezier(
                0.52,
                1.64,
                0.37,
                0.66
              );
              transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
            }
          }
        }
      }
    }
    .serviceLink {
      li {
        width: 20%;
        position: relative;
        cursor: pointer;
        &.active {
          background-color: rgba(18, 36, 90, 0.6);
          svg {
            path {
              fill: $secondary-color;
            }
          }
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 5px;
            background-color: $secondary-color;
            width: 100%;
          }
        }
        .serviceLinks {
          text-align: center;
          padding: 8px 10px;
          display: block;
          position: relative;
          span {
            color: $white-color;
            display: block;
            margin-top: 6px;
            font-weight: 500;
            font-size: 15px;
            @include medium_device {
              display: none;
            }
          }
        }
        &:before {
          content: "";
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #4267d7;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
          -webkit-transition: all 0.5s ease-out;
          transition: all 0.5s ease-out;
        }
        &:hover {
          &:before {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            -webkit-transition-timing-function: cubic-bezier(
              0.52,
              1.64,
              0.37,
              0.66
            );
            transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
          }
        }
      }
      svg {
        @include large_device {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.whiteColor{
  header{
    .cheapestDetail{
      .title,
      .desc,
      .playVideo span,
      .tabList p{
        color: white !important;
      }
    }
    .bottomStar div{
      color: white !important;
    }
  }
}